
import { Options, Vue } from 'vue-class-component';
import DriverNavbar from "@/components/driverNavbar.vue";

@Options({
    components: {
        DriverNavbar
    },
})
export default class DriverLayout extends Vue {

    created() {
    }

}
